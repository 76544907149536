import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_GEONAME_BY_GID = createRequestTypes('GET_GEONAME_BY_GID');
export const getGeonameByGID = {
  request: gid => action(GET_GEONAME_BY_GID[REQUEST], { gid }),
  success: data => action(GET_GEONAME_BY_GID[SUCCESS], { data }),
  failure: error => action(GET_GEONAME_BY_GID[FAILURE], { error }),
};

export const GET_USERS = createRequestTypes('GET_USERS');
export const getUsers = {
  request: search => action(GET_USERS[REQUEST], search),
  success: data => action(GET_USERS[SUCCESS], { data }),
  failure: error => action(GET_USERS[FAILURE], { error }),
};

export const GET_USER_DATA = createRequestTypes('GET_USER_DATA');
export const getUserData = {
  request: id => action(GET_USER_DATA[REQUEST], { id }),
  success: data => action(GET_USER_DATA[SUCCESS], { data }),
  failure: error => action(GET_USER_DATA[FAILURE], { error }),
};

export const GET_USER_LOGIN_HISTORY = createRequestTypes('GET_USER_LOGIN_HISTORY');
export const getUserLoginHistory = {
  request: data => action(GET_USER_LOGIN_HISTORY[REQUEST], { ...data }),
  success: data => action(GET_USER_LOGIN_HISTORY[SUCCESS], { data }),
  failure: error => action(GET_USER_LOGIN_HISTORY[FAILURE], { error }),
};

export const GET_USER_OPERATION_HISTORY = createRequestTypes('GET_USER_OPERATION_HISTORY');
export const getUserOperationHistory = {
  request: data => action(GET_USER_OPERATION_HISTORY[REQUEST], { ...data }),
  success: data => action(GET_USER_OPERATION_HISTORY[SUCCESS], { data }),
  failure: error => action(GET_USER_OPERATION_HISTORY[FAILURE], { error }),
};

export const GET_USER_INFO = createRequestTypes('GET_USER_INFO');
export const getUserInfo = {
  request: id => action(GET_USER_INFO[REQUEST], { id }),
  success: () => action(GET_USER_INFO[SUCCESS]),
  failure: () => action(GET_USER_INFO[FAILURE]),
};

export const VERIFY_USER = createRequestTypes('VERIFY_USER');
export const verifyUser = {
  request: id => action(VERIFY_USER[REQUEST], { id }),
  success: data => action(VERIFY_USER[SUCCESS], { data }),
  failure: error => action(VERIFY_USER[FAILURE], { error }),
};

export const REJECT_USER = createRequestTypes('REJECT_USER');
export const rejectUser = {
  request: id => action(REJECT_USER[REQUEST], { id }),
  success: data => action(REJECT_USER[SUCCESS], { data }),
  failure: error => action(REJECT_USER[FAILURE], { error }),
};

export const UNBLOCK_USER = createRequestTypes('UNBLOCK_USER');
export const unblockUser = {
  request: id => action(UNBLOCK_USER[REQUEST], { id }),
  success: () => action(UNBLOCK_USER[SUCCESS]),
  failure: error => action(UNBLOCK_USER[FAILURE], { error }),
};

export const VERIFY_PARTNER = createRequestTypes('VERIFY_PARTNER');
export const verifyPartner = {
  request: id => action(VERIFY_PARTNER[REQUEST], { id }),
  success: data => action(VERIFY_PARTNER[SUCCESS], { data }),
  failure: error => action(VERIFY_PARTNER[FAILURE], { error }),
};

export const GET_USER_DOCUMENT_FILE = createRequestTypes('GET_USER_DOCUMENT_FILE');
export const getUserDocumentFile = {
  request: (id, documentId) => action(GET_USER_DOCUMENT_FILE[REQUEST], { id, documentId }),
  success: data => action(GET_USER_DOCUMENT_FILE[SUCCESS], { data }),
  failure: error => action(GET_USER_DOCUMENT_FILE[FAILURE], { error }),
};

export const GET_USER_DOCUMENT_LIST = createRequestTypes('GET_USER_DOCUMENT_LIST');
export const getUserDocumentList = {
  request: userId => action(GET_USER_DOCUMENT_LIST[REQUEST], { userId }),
  success: data => action(GET_USER_DOCUMENT_LIST[SUCCESS], { data }),
  failure: error => action(GET_USER_DOCUMENT_LIST[FAILURE], { error }),
};

export const VERIFY_USER_DOCUMENT = createRequestTypes('VERIFY_USER_DOCUMENT');
export const verifyUserDocument = {
  request: (id, documentId) => action(VERIFY_USER_DOCUMENT[REQUEST], { id, documentId }),
  success: doc => action(VERIFY_USER_DOCUMENT[SUCCESS], { doc }),
  failure: (doc, error) => action(VERIFY_USER_DOCUMENT[FAILURE], { doc, error }),
};

export const UPLOAD_USER_DOCUMENT = createRequestTypes('UPLOAD_USER_DOCUMENT');
export const uploadUserDocument = {
  request: data => action(UPLOAD_USER_DOCUMENT[REQUEST], { data }),
  success: data => action(UPLOAD_USER_DOCUMENT[SUCCESS], { data }),
  failure: error => action(UPLOAD_USER_DOCUMENT[FAILURE], { error }),
};

export const GET_WITHDRAWAL_PROOF = createRequestTypes('GET_WITHDRAWAL_PROOF');
export const getWithdrawalProof = {
  request: id => action(GET_WITHDRAWAL_PROOF[REQUEST], { id }),
  success: data => action(GET_WITHDRAWAL_PROOF[SUCCESS], { data }),
  failure: error => action(GET_WITHDRAWAL_PROOF[FAILURE], { error }),
};

export const editUserInfo = createFormAction('EDIT_USER_INFO');

export const GET_USER_ACCOUNT = createRequestTypes('GET_USER_ACCOUNT');
export const getUserAccount = {
  request: (userId, accountId) => action(GET_USER_ACCOUNT[REQUEST], { userId, accountId }),
  success: data => action(GET_USER_ACCOUNT[SUCCESS], { data }),
  failure: error => action(GET_USER_ACCOUNT[FAILURE], { error }),
};

export const GET_USER_ACCOUNTS = createRequestTypes('GET_USER_ACCOUNTS');
export const getUserAccounts = {
  request: data => action(GET_USER_ACCOUNTS[REQUEST], data),
  success: data => action(GET_USER_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(GET_USER_ACCOUNTS[FAILURE], { error }),
};

export const GET_USER_ACCOUNTS_PREFETCH = createRequestTypes('GET_USER_ACCOUNTS_PREFETCH');
export const getUserAccountsPrefetch = {
  request: data => action(GET_USER_ACCOUNTS_PREFETCH[REQUEST], data),
  success: data => action(GET_USER_ACCOUNTS_PREFETCH[SUCCESS], { data }),
  failure: error => action(GET_USER_ACCOUNTS_PREFETCH[FAILURE], { error }),
};

export const BLOCK_USER_ACCOUNT = createRequestTypes('BLOCK_USER_ACCOUNT');
export const blockUserAccount = {
  request: (userId, accountId) => action(BLOCK_USER_ACCOUNT[REQUEST], { userId, accountId }),
  success: accountId => action(BLOCK_USER_ACCOUNT[SUCCESS], { accountId }),
  failure: (accountId, error) => action(BLOCK_USER_ACCOUNT[FAILURE], { accountId, error }),
};

export const UNBLOCK_USER_ACCOUNT = createRequestTypes('UNBLOCK_USER_ACCOUNT');
export const unblockUserAccount = {
  request: (userId, accountId) => action(UNBLOCK_USER_ACCOUNT[REQUEST], { userId, accountId }),
  success: accountId => action(UNBLOCK_USER_ACCOUNT[SUCCESS], { accountId }),
  failure: (accountId, error) => action(UNBLOCK_USER_ACCOUNT[FAILURE], { accountId, error }),
};

export const DELETE_USER_ACCOUNT = createRequestTypes('DELETE_USER_ACCOUNT');
export const deleteUserAccount = {
  request: (userId, accountId) => action(DELETE_USER_ACCOUNT[REQUEST], { userId, accountId }),
  success: accountId => action(DELETE_USER_ACCOUNT[SUCCESS], { accountId }),
  failure: (accountId, error) => action(DELETE_USER_ACCOUNT[FAILURE], { accountId, error }),
};

export const RESTORE_USER_ACCOUNT = createRequestTypes('RESTORE_USER_ACCOUNT');
export const restoreUserAccount = {
  request: (userId, accountId) => action(RESTORE_USER_ACCOUNT[REQUEST], { userId, accountId }),
  success: accountId => action(RESTORE_USER_ACCOUNT[SUCCESS], { accountId }),
  failure: (accountId, error) => action(RESTORE_USER_ACCOUNT[FAILURE], { accountId, error }),
};

export const GET_USER_UTM_HISTORY = createRequestTypes('GET_USER_UTM_HISTORY');
export const getUserUtmHistory = {
  request: (userId, search) => action(GET_USER_UTM_HISTORY[REQUEST], { userId, search }),
  success: data => action(GET_USER_UTM_HISTORY[SUCCESS], { data }),
  failure: error => action(GET_USER_UTM_HISTORY[FAILURE], { error }),
};

export const GET_USER_TOTAL_PAYMENTS = createRequestTypes('GET_USER_TOTAL_PAYMENTS');
export const getUserTotalPayments = {
  request: id => action(GET_USER_TOTAL_PAYMENTS[REQUEST], { id }),
  success: data => action(GET_USER_TOTAL_PAYMENTS[SUCCESS], { data }),
  failure: error => action(GET_USER_TOTAL_PAYMENTS[FAILURE], { error }),
};

export const GET_USER_PAYMENTS = createRequestTypes('GET_USER_PAYMENTS');
export const getUserPayments = {
  request: (id, search) => action(GET_USER_PAYMENTS[REQUEST], { id, search }),
  success: data => action(GET_USER_PAYMENTS[SUCCESS], { data }),
  failure: error => action(GET_USER_PAYMENTS[FAILURE], { error }),
};

export const GET_USER_INTERNAL_TRANSFERS = createRequestTypes('GET_USER_INTERNAL_TRANSFERS');
export const getUserInternalTransfers = {
  request: data => action(GET_USER_INTERNAL_TRANSFERS[REQUEST], data),
  success: data => action(GET_USER_INTERNAL_TRANSFERS[SUCCESS], { data }),
  failure: error => action(GET_USER_INTERNAL_TRANSFERS[FAILURE], { error }),
};

export const GET_USER_BONUS = createRequestTypes('GET_USER_BONUS');
export const getUserBonus = {
  request: (id, search) => action(GET_USER_BONUS[REQUEST], { id, search }),
  success: data => action(GET_USER_BONUS[SUCCESS], { data }),
  failure: error => action(GET_USER_BONUS[FAILURE], { error }),
};
export const createUserBonus = createFormAction('CREATE_USER_BONUS');
export const withdrawUserBonus = createFormAction('WITHDRAW_USER_BONUS');
export const activateUserBonus = createFormAction('ACTIVATE_USER_BONUS');

export const GET_USER_NOTES = createRequestTypes('GET_USER_NOTES');
export const getUserNotes = {
  request: id => action(GET_USER_NOTES[REQUEST], { id }),
  success: data => action(GET_USER_NOTES[SUCCESS], { data }),
  failure: error => action(GET_USER_NOTES[FAILURE], { error }),
};
export const createUserNote = createFormAction('CREATE_USER_NOTE');

export const deleteUserNote = createFormAction('DELETE_USER_NOTE');

export const changeUserPassword = createFormAction('CHANGE_USER_PASSWORD');

export const EDIT_USER_CAN_ACTIVATE_BONUSES = createRequestTypes('EDIT_USER_CAN_ACTIVATE_BONUSES');
export const editUserCanActivateBonuses = {
  request: ({ userId, canActivateBonuses }) =>
    action(EDIT_USER_CAN_ACTIVATE_BONUSES[REQUEST], { userId, canActivateBonuses }),
  success: data => action(EDIT_USER_CAN_ACTIVATE_BONUSES[SUCCESS], { data }),
  failure: error => action(EDIT_USER_CAN_ACTIVATE_BONUSES[FAILURE], { error }),
};

export const GET_ALL_TAGS = createRequestTypes('GET_ALL_TAGS');
export const getAllTags = {
  request: () => action(GET_ALL_TAGS[REQUEST]),
  success: data => action(GET_ALL_TAGS[SUCCESS], { data }),
  failure: error => action(GET_ALL_TAGS[FAILURE], { error }),
};

export const GET_USER_TAGS = createRequestTypes('GET_USER_TAGS');
export const getUserTags = {
  request: id => action(GET_USER_TAGS[REQUEST], { id }),
  success: data => action(GET_USER_TAGS[SUCCESS], { data }),
  failure: error => action(GET_USER_TAGS[FAILURE], { error }),
};

export const setUserTag = createFormAction('SET_USER_TAG');

export const DELETE_USER_TAG = createRequestTypes('DELETE_USER_TAG');
export const deleteUserTag = {
  request: (userId, tagId) => action(DELETE_USER_TAG[REQUEST], { userId, tagId }),
  success: () => action(DELETE_USER_TAG[SUCCESS]),
  failure: error => action(DELETE_USER_TAG[FAILURE], { error }),
};

export const GET_USER_PARTNER_ACCOUNTS = createRequestTypes('GET_USER_PARTNER_ACCOUNTS');
export const getUserPartnerAccounts = {
  request: userId => action(GET_USER_PARTNER_ACCOUNTS[REQUEST], { userId }),
  success: data => action(GET_USER_PARTNER_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(GET_USER_PARTNER_ACCOUNTS[FAILURE], { error }),
};

export const GET_PARTNERSHIP_CONDITIONS_LIST = createRequestTypes('GET_PARTNERSHIP_CONDITIONS_LIST');
export const getPartnershipConditionsList = {
  request: userId => action(GET_PARTNERSHIP_CONDITIONS_LIST[REQUEST], { userId }),
  success: data => action(GET_PARTNERSHIP_CONDITIONS_LIST[SUCCESS], { data }),
  failure: error => action(GET_PARTNERSHIP_CONDITIONS_LIST[FAILURE], { error }),
};

export const createUserPartnerAccount = createFormAction('CREATE_USER_PARTNER_ACCOUNT');
export const editUserPartnerAccount = createFormAction('EDIT_USER_PARTNER_ACCOUNT');

export const GET_AVAILABLE_PARTNER_CODES = createRequestTypes('GET_AVAILABLE_PARTNER_CODES');
export const getAvailablePartnerCodes = {
  request: data => action(GET_AVAILABLE_PARTNER_CODES[REQUEST], data),
  success: data => action(GET_AVAILABLE_PARTNER_CODES[SUCCESS], { data }),
  failure: error => action(GET_AVAILABLE_PARTNER_CODES[FAILURE], { error }),
};

export const generateSecurityPinCode = createFormAction('GENERATE_SECURITY_PIN_CODE');

export const editUserPartnerCode = createFormAction('EDIT_USER_PARTNER_CODE');
export const removeUserPartnerCode = createFormAction('REMOVE_USER_PARTNER_CODE');

export const editTradeAccountPartnerCode = createFormAction('EDIT_TRADE_ACCOUNT_PARTNER_CODE');
export const removeTradeAccountUserPartnerCode = createFormAction('REMOVE_TRADE_ACCOUNT_PARTNER_CODE');

export const START_IMPERSONATE = createRequestTypes('START_IMPERSONATE');
export const startImpersonate = {
  request: data => action(START_IMPERSONATE[REQUEST], data),
  success: () => action(START_IMPERSONATE[SUCCESS]),
  failure: error => action(START_IMPERSONATE[FAILURE], { error }),
};

export const GET_USER_ISSUES = createRequestTypes('GET_USER_ISSUES');
export const getUserIssues = {
  request: id => action(GET_USER_ISSUES[REQUEST], { id }),
  success: data => action(GET_USER_ISSUES[SUCCESS], { data }),
  failure: error => action(GET_USER_ISSUES[FAILURE], { error }),
};

export const rejectUserVerification = createFormAction('REJECT_USER_VERIFICATION');

export const rejectUserDocument = createFormAction('REJECT_USER_DOCUMENT');

export const rejectPartner = createFormAction('REJECT_PARTNER');

export const removePhone = createFormAction('REMOVE_PHONE');

export const blockUser = createFormAction('BLOCK_USER');

export const editLpaStatus = createFormAction('EDIT_LPA_STATUS');
export const appointLpaStatus = createFormAction('APPOINT_LPA_STATUS');
export const revokeLpaStatus = createFormAction('REVOKE_LPA_STATUS');

export const disableSupportMessages = createFormAction('DISABLE_SUPPORT_MESSAGES');
export const enableSupportMessages = createFormAction('ENABLE_SUPPORT_MESSAGES');

export const GET_USER_CARDS = createRequestTypes('GET_USER_CARDS');
export const getUserCards = {
  request: userId => action(GET_USER_CARDS[REQUEST], { userId }),
  success: data => action(GET_USER_CARDS[SUCCESS], { data }),
  failure: error => action(GET_USER_CARDS[FAILURE], { error }),
};

export const GET_CARD_PHOTO = createRequestTypes('GET_CARD_PHOTO');
export const getCardPhoto = {
  request: data => action(GET_CARD_PHOTO[REQUEST], data),
  success: data => action(GET_CARD_PHOTO[SUCCESS], { data }),
  failure: error => action(GET_CARD_PHOTO[FAILURE], { error }),
};

export const verifyUserCard = createFormAction('VERIFY_USER_CARD');
export const rejectUserCard = createFormAction('REJECT_USER_CARD');

export const addUserCard = createFormAction('ADD_USER_CARD');

export const GET_USER_AVAILABLE_DEPOSITS = createRequestTypes('GET_USER_AVAILABLE_DEPOSITS');
export const getUserAvailableDeposits = {
  request: data => action(GET_USER_AVAILABLE_DEPOSITS[REQUEST], data),
  success: data => action(GET_USER_AVAILABLE_DEPOSITS[SUCCESS], { data }),
  failure: error => action(GET_USER_AVAILABLE_DEPOSITS[FAILURE], { error }),
};

export const CONFIRM_USER_EMAIL = createRequestTypes('CONFIRM_USER_EMAIL');
export const confirmUserEmail = {
  request: data => action(CONFIRM_USER_EMAIL[REQUEST], data),
  success: data => action(CONFIRM_USER_EMAIL[SUCCESS], { data }),
  failure: error => action(CONFIRM_USER_EMAIL[FAILURE], { error }),
};

export const CANCEL_USER_BONUS = createRequestTypes('CANCEL_USER_BONUS');
export const cancelUserBonus = {
  request: data => action(CANCEL_USER_BONUS[REQUEST], data),
  success: data => action(CANCEL_USER_BONUS[SUCCESS], { data }),
  failure: error => action(CANCEL_USER_BONUS[FAILURE], { error }),
};

export const SHOW_WITHDRAW_APPOVE_MODAL = 'SHOW_WITHDRAW_APPOVE_MODAL';
export const showWithdrawApproveModal = () => action(SHOW_WITHDRAW_APPOVE_MODAL);

export const GET_MANAGER_CLIENT = createRequestTypes('GET_MANAGER_CLIENT');
export const getManagerClient = {
  request: data => action(GET_MANAGER_CLIENT[REQUEST], data),
  success: data => action(GET_MANAGER_CLIENT[SUCCESS], { data }),
  failure: error => action(GET_MANAGER_CLIENT[FAILURE], { error }),
};

export const GET_BACKOFFICE_MANAGER_LIST = createRequestTypes('GET_BACKOFFICE_MANAGER_LIST');
export const getBackofficeManagerList = {
  request: data => action(GET_BACKOFFICE_MANAGER_LIST[REQUEST], data),
  success: data => action(GET_BACKOFFICE_MANAGER_LIST[SUCCESS], { data }),
  failure: error => action(GET_BACKOFFICE_MANAGER_LIST[FAILURE], { error }),
};

export const setUserManager = createFormAction('SET_USER_MANAGER');

export const GET_FREE_CLIENTS_COUNT = createRequestTypes('GET_FREE_CLIENTS_COUNT');
export const getFreeClientsCount = {
  request: () => action(GET_FREE_CLIENTS_COUNT[REQUEST]),
  success: data => action(GET_FREE_CLIENTS_COUNT[SUCCESS], { data }),
  failure: error => action(GET_FREE_CLIENTS_COUNT[FAILURE], { error }),
};

export const editUserStatus = createFormAction('EDIT_USER_STATUS');

export const createDocument = createFormAction('CREATE_DOCUMENT');

export const GET_USER_NOTE_DOCUMENT = createRequestTypes('GET_USER_NOTE_DOCUMENT');
export const getUserNoteDocument = {
  request: data => action(GET_USER_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(GET_USER_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(GET_USER_NOTE_DOCUMENT[FAILURE], { error }),
};

export const REMOVE_USER_NOTE_DOCUMENT = createRequestTypes('REMOVE_USER_NOTE_DOCUMENT');
export const removeUserNoteDocument = {
  request: data => action(REMOVE_USER_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(REMOVE_USER_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(REMOVE_USER_NOTE_DOCUMENT[FAILURE], { error }),
};

export const GET_USER_KYC = createRequestTypes('GET_USER_KYC');
export const getUserKYC = {
  request: data => action(GET_USER_KYC[REQUEST], { data }),
  success: data => action(GET_USER_KYC[SUCCESS], { data }),
  failure: error => action(GET_USER_KYC[FAILURE], { error }),
};

export const GET_TREDERO_CUSTOM_USER_KYC = createRequestTypes('GET_TREDERO_CUSTOM_USER_KYC');
export const getTrederoCustomUserKYC = {
  request: data => action(GET_TREDERO_CUSTOM_USER_KYC[REQUEST], { data }),
  success: data => action(GET_TREDERO_CUSTOM_USER_KYC[SUCCESS], { data }),
  failure: error => action(GET_TREDERO_CUSTOM_USER_KYC[FAILURE], { error }),
};

export const editUserNote = createFormAction('EDIT_USER_NOTE');
export const editUserKYC = createFormAction('EDIT_USER_KYC');
export const editTrederoCustomUserKYC = createFormAction('EDIT_TREDERO_CUSTOM_USER_KYC');

export const createUser = createFormAction('CREATE_USER');

export const GET_SERVER_LIST = createRequestTypes('GET_SERVER_LIST');
export const getServerList = {
  request: () => action(GET_SERVER_LIST[REQUEST]),
  success: data => action(GET_SERVER_LIST[SUCCESS], { data }),
  failure: error => action(GET_SERVER_LIST[FAILURE], { error }),
};

export const createUserAccount = createFormAction('CREATE_USER_ACCOUNT');

export const GET_CALL_PROVIDERS_LIST = createRequestTypes('GET_CALL_PROVIDERS_LIST');
export const getCallProvidersList = {
  request: () => action(GET_CALL_PROVIDERS_LIST[REQUEST]),
  success: data => action(GET_CALL_PROVIDERS_LIST[SUCCESS], { data }),
  failure: error => action(GET_CALL_PROVIDERS_LIST[FAILURE], { error }),
};

export const MAKE_A_CALL = createRequestTypes('MAKE_A_CALL');
export const makeACall = {
  request: data => action(MAKE_A_CALL[REQUEST], { data }),
  success: data => action(MAKE_A_CALL[SUCCESS], { data }),
  failure: error => action(MAKE_A_CALL[FAILURE], { error }),
};

export const GET_CURRENCIES = createRequestTypes('GET_CURRENCIES');
export const getCurrencies = {
  request: () => action(GET_CURRENCIES[REQUEST]),
  success: data => action(GET_CURRENCIES[SUCCESS], { data }),
  failure: error => action(GET_CURRENCIES[FAILURE], { error }),
};

export const GET_COUNTRIES = createRequestTypes('GET_COUNTRIES');
export const getCountries = {
  request: () => action(GET_COUNTRIES[REQUEST]),
  success: data => action(GET_COUNTRIES[SUCCESS], { data }),
  failure: error => action(GET_COUNTRIES[FAILURE], { error }),
};

export const GET_LPA_INFO = createRequestTypes('GET_LPA_INFO');
export const getLpaInfo = {
  request: id => action(GET_LPA_INFO[REQUEST], { id }),
  success: data => action(GET_LPA_INFO[SUCCESS], { data }),
  failure: error => action(GET_LPA_INFO[FAILURE], { error }),
};

export const GET_LPAS = createRequestTypes('GET_LPAS');
export const getLpas = {
  request: search => action(GET_LPAS[REQUEST], search),
  success: data => action(GET_LPAS[SUCCESS], { data }),
  failure: error => action(GET_LPAS[FAILURE], { error }),
};
export const SET_CLIENT_TYPE = 'SET_CLIENT_TYPE';
export const setClientType = { request: data => action(SET_CLIENT_TYPE, { data }) };

export const initializeBackofficeUserImport = createFormAction('INITIALIZE_BO_USER_IMPORT');

export const generateAndDownloadSumSubReport = createFormAction('GENERATE_AND_DOWNLOAD_SUMSUB_REPORT');

export const GET_BO_USER_IMPORTS = createRequestTypes('GET_BO_USER_IMPORTS');
export const getBackofficeUserImports = {
  request: data => action(GET_BO_USER_IMPORTS[REQUEST], data),
  success: data => action(GET_BO_USER_IMPORTS[SUCCESS], { data }),
  failure: error => action(GET_BO_USER_IMPORTS[FAILURE], { error }),
};

export const DOWNLOAD_BO_USER_IMPORT = createRequestTypes('DOWNLOAD_BO_USER_IMPORT');
export const downloadBackofficeUserImport = {
  request: id => action(DOWNLOAD_BO_USER_IMPORT[REQUEST], id),
  success: data => action(DOWNLOAD_BO_USER_IMPORT[SUCCESS], { data }),
  failure: error => action(DOWNLOAD_BO_USER_IMPORT[FAILURE], { error }),
};

export const SET_IMPORT_EXTENSION = 'SET_IMPORT_EXTENSION';
export const setImportExtension = { request: data => action(SET_IMPORT_EXTENSION, data) };

export const GET_USER_STATUSES = createRequestTypes('GET_USER_STATUSES');
export const getUserStatuses = {
  request: () => action(GET_USER_STATUSES[REQUEST]),
  success: data => action(GET_USER_STATUSES[SUCCESS], { data }),
  failure: error => action(GET_USER_STATUSES[FAILURE], { error }),
};

export const depositWalletBalance = createFormAction('DEPOSIT_WALLET_BALANCE');
export const withdrawWalletBalance = createFormAction('WITHDRAW_WALLET_BALANCE');
