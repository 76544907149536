import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import {
  PageContentPreloader,
  PageLayout,
  SearchPanel,
  PaginationTable,
  NoItemsPanel,
  BasicModal,
  NewDefaultButton,
} from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';

import InvestCRMWithdrawlForm from '../../_components/InvestCRMWithdrawlForm';

import { boGetInvestCRMStrategyInvestors } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const InvestStrategyPageNew = ({
  investCRMStrategyInvestors,
  investCRMStrategyInvestorsAreLoaded,
  getInvestStrategyInvestors,
  investFullAccessPerm,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedInvestmentId, setSelectedInvestmentId] = useState(null);
  const toggleWithdrawalModal = () => setIsWithdrawalModalOpen(!isWithdrawalModalOpen);
  const {
    params: { strategy },
  } = match;
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const getList = useCallback(
    query => {
      getInvestStrategyInvestors({ strategyId: strategy, ...query });
    },
    [getInvestStrategyInvestors]
  );

  useEffect(() => {
    getList({ strategyId: strategy, search: query });
  }, [getList]);

  const openInvestorAccount = accountId => history.push(`/backoffice/users/${accountId}`);

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        accessor: 'investor__first_name',
        Header: 'boInvestFullName',
        Cell: ({
          row: {
            original: { userName, userEmail, investorId },
          },
        }) => (
          <div className="InvestStrategyTable__basic-info" onClick={() => openInvestorAccount(investorId)}>
            <Gravatar md5={md5(userEmail)} size={33} />
            <span className="user-name">{userName}</span>
          </div>
        ),
      },
      {
        accessor: 'investor__email',
        Header: 'boInvestEmail',
        Cell: ({
          row: {
            original: { userEmail },
          },
        }) => <span className="InvestStrategyTable__email">{userEmail}</span>,
      },
      {
        accessor: 'equity',
        Header: 'boInvestEquity',
        Cell: ({
          row: {
            original: { equity },
          },
        }) => <span className="InvestStrategyTable__equity">{`${equity.amount} ${equity.currency ?? ''}`}</span>,
      },
      {
        Header: 'boInvestTotalProfit',
        Cell: ({
          row: {
            original: { totalProfit },
          },
        }) => (
          <span className="InvestStrategyTable__total-profit">{`${totalProfit.amount} ${
            totalProfit.currency ?? ''
          }`}</span>
        ),
      },
      {
        Header: 'boInvestRewardAccount',
        Cell: ({
          row: {
            original: { masterAccount },
          },
        }) => <span className="InvestStrategyTable__reward-account">{masterAccount}</span>,
      },
      {
        Header: 'boInvestStrategyActivity',
        Cell: ({
          row: {
            original: { id, enabled, investorId },
          },
        }) => {
          return (
            <div className="InvestStrategyTable__status_cell">
              {!!enabled && (
                <NewDefaultButton
                  textId="justDisable"
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    setSelectedUserId(investorId);
                    setSelectedInvestmentId(id);
                    toggleWithdrawalModal();
                  }}
                />
              )}
              <div className={`status ${!!enabled ? 'active' : ''}`} />
            </div>
          );
        },
      },
    ],
    [query]
  );
  /* eslint-enable */

  return (
    <PageLayout
      captionSlug="boInvestStrategyTitle"
      captionValues={{ strategy: investCRMStrategyInvestors.strategy }}
      withBackButton>
      <PageContentPreloader ready={investCRMStrategyInvestorsAreLoaded} type="bigLine" firstLaunchOnly>
        <SearchPanel
          location={location}
          loading={!investCRMStrategyInvestorsAreLoaded}
          history={history}
          getList={getList}
          params={{ strategyName: strategy, search: query }}
          searchTextId="boInvestSearchByEmail"
        />
        {investCRMStrategyInvestors.count ? (
          <div className={classNames('InvestStrategyTable', { full: investFullAccessPerm })}>
            <PaginationTable
              data={investCRMStrategyInvestors}
              columns={columns}
              loading={!investCRMStrategyInvestorsAreLoaded}
              getList={getList}
              location={location}
              history={history}
              params={{ strategyId: strategy, search: query }}
              isNewTable
              noResponsive
            />
          </div>
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noStrategiesMatchingTheParameters" />
        )}
      </PageContentPreloader>
      <BasicModal
        isOpen={isWithdrawalModalOpen}
        captionId="boCloseInvestmentInStrategy"
        onRequestClose={toggleWithdrawalModal}>
        <InvestCRMWithdrawlForm
          userId={selectedUserId}
          strategyId={strategy}
          search={query}
          investmentId={selectedInvestmentId}
          toggleWithdrawalModal={toggleWithdrawalModal}
        />
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

InvestStrategyPageNew.propTypes = {
  investCRMStrategyInvestors: PropTypes.object.isRequired,
  investCRMStrategyInvestorsAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategyInvestors: PropTypes.func.isRequired,
  investFullAccessPerm: PropTypes.bool,
  theme: PropTypes.object,
};

InvestStrategyPageNew.defaultProps = {
  investFullAccessPerm: false,
  theme: {},
};

const ThemedInvestInvestStrategyPage = withTheme()(
  connect(
    state => ({
      investCRMStrategyInvestors: state.backoffice.investCRM.investCRMStrategyInvestors,
      investCRMStrategyInvestorsAreLoaded: state.backoffice.investCRM.investCRMStrategyInvestorsAreLoaded,
      investFullAccessPerm:
        state.user.profile.perms.investFullAccessPerm || state.user.profile.perms.managerFullAccessPerm,
    }),
    {
      getInvestStrategyInvestors: ({ strategyId, search }) =>
        boGetInvestCRMStrategyInvestors.request(strategyId, search),
    }
  )(InvestStrategyPageNew)
);

export default ThemedInvestInvestStrategyPage;
export { InvestStrategyPageNew };
