import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const CREATE_BO_PAYMENT_EXPORT = createRequestTypes('CREATE_BO_PAYMENT_EXPORT');
export const createBackOfficePaymentExport = {
  request: ({ exportsType, pushToExportsFunc, ext, timePeriod, filters }) =>
    action(CREATE_BO_PAYMENT_EXPORT[REQUEST], { exportsType, pushToExportsFunc, ext, timePeriod, filters }),
  success: data => action(CREATE_BO_PAYMENT_EXPORT[SUCCESS], { data }),
  failure: error => action(CREATE_BO_PAYMENT_EXPORT[FAILURE], { error }),
};

export const GET_BO_PAYMENT_EXPORTS = createRequestTypes('GET_BO_PAYMENT_EXPORTS');

export const getBackOfficePaymentExports = {
  request: ({ search, exportsType }) => action(GET_BO_PAYMENT_EXPORTS[REQUEST], { search, exportsType }),
  success: data => action(GET_BO_PAYMENT_EXPORTS[SUCCESS], { data }),
  failure: error => action(GET_BO_PAYMENT_EXPORTS[FAILURE], { error }),
};

export const DOWNLOAD_BO_PAYMENT_EXPORT = createRequestTypes('DOWNLOAD_BO_PAYMENT_EXPORT');
export const downloadBackofficePaymentExport = {
  request: (id, name) => action(DOWNLOAD_BO_PAYMENT_EXPORT[REQUEST], id, name),
  success: data => action(DOWNLOAD_BO_PAYMENT_EXPORT[SUCCESS], { data }),
  failure: error => action(DOWNLOAD_BO_PAYMENT_EXPORT[FAILURE], { error }),
};

export const GET_BO_PAYMENT_EXPORT = createRequestTypes('GET_BO_PAYMENT_EXPORT');
export const getBackofficeUserExport = {
  request: id => action(GET_BO_PAYMENT_EXPORT[REQUEST], id),
  success: data => action(GET_BO_PAYMENT_EXPORT[SUCCESS], { data }),
  failure: error => action(GET_BO_PAYMENT_EXPORT[FAILURE], { error }),
};

export const GET_DEPOSITS = createRequestTypes('GET_DEPOSITS');
export const getDeposits = {
  request: search => action(GET_DEPOSITS[REQUEST], { search }),
  success: data => action(GET_DEPOSITS[SUCCESS], { data }),
  failure: error => action(GET_DEPOSITS[FAILURE], { error }),
};

export const GET_USER_DEPOSITS = createRequestTypes('GET_USER_DEPOSITS');
export const getUserDeposits = {
  request: user => action(GET_USER_DEPOSITS[REQUEST], { user }),
  success: data => action(GET_USER_DEPOSITS[SUCCESS], { data }),
  failure: error => action(GET_USER_DEPOSITS[FAILURE], { error }),
};

export const GET_DEPOSIT = createRequestTypes('GET_DEPOSIT');
export const getDeposit = {
  request: id => action(GET_DEPOSIT[REQUEST], { id }),
  success: data => action(GET_DEPOSIT[SUCCESS], { data }),
  failure: error => action(GET_DEPOSIT[FAILURE], { error }),
};

export const GET_DEPOSIT_PROOF = createRequestTypes('GET_DEPOSIT_PROOF');
export const getDepositProof = {
  request: id => action(GET_DEPOSIT_PROOF[REQUEST], { id }),
  success: data => action(GET_DEPOSIT_PROOF[SUCCESS], { data }),
  failure: error => action(GET_DEPOSIT_PROOF[FAILURE], { error }),
};

export const GET_PAYMENT_PROOF = createRequestTypes('GET_PAYMENT_PROOF');
export const getPaymentProof = {
  request: id => action(GET_PAYMENT_PROOF[REQUEST], { id }),
  success: data => action(GET_PAYMENT_PROOF[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_PROOF[FAILURE], { error }),
};

export const rejectDepositProof = createFormAction('REJECT_DEPOSIT_PROOF');

export const VERIFY_DEPOSIT_PROOF = createRequestTypes('VERIFY_DEPOSIT_PROOF');
export const verifyDepositProof = {
  request: id => action(VERIFY_DEPOSIT_PROOF[REQUEST], { id }),
  success: doc => action(VERIFY_DEPOSIT_PROOF[SUCCESS], { doc }),
  failure: (doc, error) => action(VERIFY_DEPOSIT_PROOF[FAILURE], { doc, error }),
};

export const ACTION_DEPOSIT = createRequestTypes('ACTION_DEPOSIT');
export const actionDeposit = {
  request: (id, actionType) => action(ACTION_DEPOSIT[REQUEST], { id, actionType }),
  success: data => action(ACTION_DEPOSIT[SUCCESS], { data }),
  failure: error => action(ACTION_DEPOSIT[FAILURE], { error }),
};

export const GET_DEPOSIT_PAYMENT_SYSTEMS = createRequestTypes('GET_DEPOSIT_PAYMENT_SYSTEMS');
export const getDepositPaymentSystems = {
  request: () => action(GET_DEPOSIT_PAYMENT_SYSTEMS[REQUEST]),
  success: systems => action(GET_DEPOSIT_PAYMENT_SYSTEMS[SUCCESS], { systems }),
  failure: error => action(GET_DEPOSIT_PAYMENT_SYSTEMS[FAILURE], { error }),
};

export const GET_WITHDRAWALS = createRequestTypes('GET_WITHDRAWALS');
export const getWithdrawals = {
  request: search => action(GET_WITHDRAWALS[REQUEST], { search }),
  success: data => action(GET_WITHDRAWALS[SUCCESS], { data }),
  failure: error => action(GET_WITHDRAWALS[FAILURE], { error }),
};

export const GET_USER_WITHDRAWALS = createRequestTypes('GET_USER_WITHDRAWALS');
export const getUserWithdrawals = {
  request: user => action(GET_USER_WITHDRAWALS[REQUEST], { user }),
  success: data => action(GET_USER_WITHDRAWALS[SUCCESS], { data }),
  failure: error => action(GET_USER_WITHDRAWALS[FAILURE], { error }),
};

export const GET_WITHDRAWAL = createRequestTypes('GET_WITHDRAWAL');
export const getWithdrawal = {
  request: id => action(GET_WITHDRAWAL[REQUEST], { id }),
  success: data => action(GET_WITHDRAWAL[SUCCESS], { data }),
  failure: error => action(GET_WITHDRAWAL[FAILURE], { error }),
};

export const SET_WITHDRAWAL_ERROR = createRequestTypes('SET_WITHDRAWAL_ERROR');
export const setWithdrawalError = {
  request: data => action(SET_WITHDRAWAL_ERROR[REQUEST], { data }),
  success: data => action(SET_WITHDRAWAL_ERROR[SUCCESS], { data }),
  failure: error => action(SET_WITHDRAWAL_ERROR[FAILURE], { error }),
};

export const ACTION_WITHDRAWAL = createRequestTypes('ACTION_WITHDRAWAL');
export const actionWithdrawal = {
  request: (id, actionType) => action(ACTION_WITHDRAWAL[REQUEST], { id, actionType }),
  success: data => action(ACTION_WITHDRAWAL[SUCCESS], { data }),
  failure: error => action(ACTION_WITHDRAWAL[FAILURE], { error }),
};

export const GET_WITHDRAWAL_PAYMENT_SYSTEMS = createRequestTypes('GET_WITHDRAWAL_PAYMENT_SYSTEMS');
export const getWithdrawalPaymentSystems = {
  request: () => action(GET_WITHDRAWAL_PAYMENT_SYSTEMS[REQUEST]),
  success: systems => action(GET_WITHDRAWAL_PAYMENT_SYSTEMS[SUCCESS], { systems }),
  failure: error => action(GET_WITHDRAWAL_PAYMENT_SYSTEMS[FAILURE], { error }),
};

export const rejectWithdrawal = createFormAction('REJECT_WITHDRAWAL');

export const failWithdraw = createFormAction('FAIL_WITHDRAW');

export const rejectDeposit = createFormAction('REJECT_DEPOSIT');

export const editDepositAmount = createFormAction('EDIT_DEPOSIT_AMOUNT');

export const editAdditionalField = createFormAction('EDIT_ADDITIONAL_FIELD');

export const approveWithdrawal = createFormAction('APPROVE_WITHDRAWAL');

export const GET_USER_TRANSACTIONS = createRequestTypes('GET_USER_TRANSACTIONS');
export const getUserTransations = {
  request: data => action(GET_USER_TRANSACTIONS[REQUEST], { data }),
  success: data => action(GET_USER_TRANSACTIONS[SUCCESS], { data }),
  failure: error => action(GET_USER_TRANSACTIONS[FAILURE], { error }),
};

export const GET_OPEN_ORDERS = createRequestTypes('GET_OPEN_ORDERS');
export const getOpenOrders = {
  request: (userId, accountId) => action(GET_OPEN_ORDERS[REQUEST], { userId, accountId }),
  success: data => action(GET_OPEN_ORDERS[SUCCESS], { data }),
  failure: (accountId, error) => action(GET_OPEN_ORDERS[FAILURE], { accountId, error }),
};

export const GET_CLOSED_ORDERS = createRequestTypes('GET_CLOSED_ORDERS');
export const getClosedOrders = {
  request: data => action(GET_CLOSED_ORDERS[REQUEST], data),
  success: data => action(GET_CLOSED_ORDERS[SUCCESS], { data }),
  failure: (accountId, error) => action(GET_CLOSED_ORDERS[FAILURE], { accountId, error }),
};

export const GET_DELAYED_ORDERS = createRequestTypes('GET_DELAYED_ORDERS');
export const getDelayedOrders = {
  request: data => action(GET_DELAYED_ORDERS[REQUEST], data),
  success: data => action(GET_DELAYED_ORDERS[SUCCESS], { data }),
  failure: (accountId, error) => action(GET_DELAYED_ORDERS[FAILURE], { accountId, error }),
};

export const GET_DEFERRED_ORDERS = createRequestTypes('GET_DEFERRED_ORDERS');
export const getDeferredOrders = {
  request: data => action(GET_DEFERRED_ORDERS[REQUEST], data),
  success: data => action(GET_DEFERRED_ORDERS[SUCCESS], { data }),
  failure: (accountId, error) => action(GET_DEFERRED_ORDERS[FAILURE], { accountId, error }),
};

export const GET_EDIT_HISTORY = createRequestTypes('GET_EDIT_HISTORY');
export const getEditHistory = {
  request: id => action(GET_EDIT_HISTORY[REQUEST], { id }),
  success: data => action(GET_EDIT_HISTORY[SUCCESS], { data }),
  failure: error => action(GET_EDIT_HISTORY[FAILURE], { error }),
};

export const GET_ORDER_DETAILS = createRequestTypes('GET_ORDER_DETAILS');
export const getOrderDetails = {
  request: data => action(GET_ORDER_DETAILS[REQUEST], data),
  success: data => action(GET_ORDER_DETAILS[SUCCESS], { data }),
  failure: error => action(GET_ORDER_DETAILS[FAILURE], { error }),
};

export const editOrder = createFormAction('EDIT_ORDER');

export const REMOVE_ORDER = createRequestTypes('REMOVE_ORDER');
export const removeOrder = {
  request: data => action(REMOVE_ORDER[REQUEST], data),
  success: data => action(REMOVE_ORDER[SUCCESS], { data }),
  failure: error => action(REMOVE_ORDER[FAILURE], { error }),
};

export const GET_APPROVAL_HISTORY = createRequestTypes('GET_APPROVAL_HISTORY');
export const getApprovalHistory = {
  request: id => action(GET_APPROVAL_HISTORY[REQUEST], { id }),
  success: data => action(GET_APPROVAL_HISTORY[SUCCESS], { data }),
  failure: error => action(GET_APPROVAL_HISTORY[FAILURE], { error }),
};

export const GET_APPROVAL_GROUP = createRequestTypes('GET_APPROVAL_GROUP');
export const getApprovalGroup = {
  request: id => action(GET_APPROVAL_GROUP[REQUEST], { id }),
  success: data => action(GET_APPROVAL_GROUP[SUCCESS], { data }),
  failure: error => action(GET_APPROVAL_GROUP[FAILURE], { error }),
};

export const GET_WITHDRAW_APPROVAL_GROUP_LIST = createRequestTypes('GET_WITHDRAW_APPROVAL_GROUP_LIST');
export const getWithdrawApprovalGroupList = {
  request: data => action(GET_WITHDRAW_APPROVAL_GROUP_LIST[REQUEST], data),
  success: data => action(GET_WITHDRAW_APPROVAL_GROUP_LIST[SUCCESS], { data }),
  failure: error => action(GET_WITHDRAW_APPROVAL_GROUP_LIST[FAILURE], { error }),
};

export const GET_APPROVAL_STEPS = createRequestTypes('GET_APPROVAL_STEPS');
export const getApprovalSteps = {
  request: id => action(GET_APPROVAL_STEPS[REQUEST], { id }),
  success: data => action(GET_APPROVAL_STEPS[SUCCESS], { data }),
  failure: error => action(GET_APPROVAL_STEPS[FAILURE], { error }),
};

export const REJECT_WITHDRAW = createRequestTypes('REJECT_WITHDRAW');
export const rejectWithdraw = {
  request: data => action(REJECT_WITHDRAW[REQUEST], { data }),
  success: data => action(REJECT_WITHDRAW[SUCCESS], { data }),
  failure: error => action(REJECT_WITHDRAW[FAILURE], { error }),
};

export const GET_PAYMENT_WITHDRAWAL_STATUSES = createRequestTypes('GET_PAYMENT_WITHDRAWAL_STATUSES');
export const getPaymentWithdrawalStatuses = {
  request: () => action(GET_PAYMENT_WITHDRAWAL_STATUSES[REQUEST]),
  success: data => action(GET_PAYMENT_WITHDRAWAL_STATUSES[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_WITHDRAWAL_STATUSES[FAILURE], { error }),
};

export const GET_PAYMENT_DEPOSIT_STATUSES = createRequestTypes('GET_PAYMENT_DEPOSIT_STATUSES');
export const getPaymentDepositStatuses = {
  request: () => action(GET_PAYMENT_DEPOSIT_STATUSES[REQUEST]),
  success: data => action(GET_PAYMENT_DEPOSIT_STATUSES[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_DEPOSIT_STATUSES[FAILURE], { error }),
};

export const changePaymentStatus = createFormAction('CHANGE_PAYMENT_STATUS');
