import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentPage__top-info {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 60px 10px 30px);
    margin-bottom: rem(20px);
    position: relative;
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    .PaymentHidden {
      opacity: 0;
    }
    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 5px;
        span {
          font-size: rem(13px);
          display: block;
          margin-bottom: 5px;
        }
        &:first-child {
          font-size: rem(18px);
        }
      }
      &.PaymentPage__additional-info {
        justify-content: flex-start;
        li {
          &:first-child {
            font-size: rem(15px);
          }
          span {
            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .PaymentPage {
    &__back-to {
      font-size: 13px;
      line-height: 18px;
      color: #777777;
      margin: -14px 0 0 0;
      padding: 10px 0 10px 14px;
      position: relative;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:before {
        content: '';
        width: 10px;
        height: 1px;
        background: #777777;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__top-info {
      padding: 20px 20px 25px 20px;

      @include respond-to(sm) {
        padding: 15px 10px 20px 10px;
      }
    }

    /* ^^^ grid-template-columns */
    &__top-info-list,
    &__additional-info-list {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(min-content, 180px));
      justify-content: space-between;
      row-gap: 20px;
      column-gap: 10px;

      @include respond-to(sm) {
        display: block !important;
      }

      @media (min-width: 878px) {
        grid-template-columns: repeat(4, minmax(min-content, 180px));
      }

      @media (min-width: 1200px) {
        grid-template-columns: repeat(5, minmax(min-content, 180px));
      }

      @media (min-width: 1667px) {
        grid-template-columns: repeat(6, minmax(min-content, 180px));
      }
    }

    &__top-info-item {
      &-oldWallet {
        display: flex;
        align-items: center;
        gap: 2px;

        &-tooltip {
          position: relative;
          top: -6px;
          height: 20px;

          span {
            margin-bottom: 0;
          }

          @include respond-to(sm) {
            width: initial !important;
          }

          @include respond-to(xs) {
            top: -1px;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        @include respond-to(sm) {
          width: 40% !important;
          margin-right: 10px;
        }
      }
    }

    &__top-info-item,
    &__additional-info-item {
      margin: 0 0 0 0 !important;
      padding: 0 0 0 0 !important;

      @include respond-to(sm) {
        flex-direction: row !important;
        align-items: center;
        margin: 0 0 7px 0 !important;
      }

      &:first-child {
        @include respond-to(sm) {
          font-size: 15px !important;
        }
      }

      &:last-child {
        @include respond-to(sm) {
          margin: 0 0 0 0 !important;
        }
      }

      & > span {
        @include respond-to(sm) {
          width: 40%;
          margin: 0 10px 0 0 !important;
        }
      }

      div {
        font-size: 15px;

        @include respond-to(sm) {
          width: 60%;
          font-size: 13px;
        }
      }

      .email {
        display: flex;
        word-break: break-all;
        > .ActionsButton {
          display: flex;
        }
      }
    }

    &__additional-info-list {
      margin: 20px 0 0 0;
    }

    &__buttons-inner {
      display: flex;
      gap: 30px;

      @include respond-to(sm) {
        gap: 15px;
      }

      button {
        margin: 25px 0 0 0;

        @include respond-to(sm) {
          width: calc(50% - 10px);
          margin: 20px 0 0 0;
        }
      }
    }

    &__approve-wrap {
      margin: 0 0 20px 0;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PaymentPage__top-info {
      background-color: ${colors.white.hex};
      &:after {
        background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }
      ul {
        li {
          span {
            color: ${colors.gray.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
