import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { PaginationTable, MoneyCell, DefaultButton, ActionsButton, DateCell, TableLoader } from 'common/components';
import { DepositIcon, WithdrawalIcon, CrossIcon, EditIcon } from 'common/icons';

import { staticStyles } from './style';

const InvestmentsTable = ({
  data,
  activeTab,
  prevTab,
  onRowClick,
  loading,
  getList,
  location,
  tempNewInvestDisableCanselRequestButton,
  history,
  params,
  onTableAction,
  theme,
  isNewInevest,
  ...props
}) => {
  const investmentIdSlug = document.location.hostname === 'portal.motforex.com' ? 'investmentIdMot' : 'investmentId';
  /* eslint-disable */
  const activeColumns = [
    {
      accessor: 'title',
      Header:
        document.location.hostname === 'my.tixee.com' ||
        document.location.hostname === 'portal.disona.com' ||
        document.location.hostname === 'portal.rock-west.com'
          ? 'rammInvestmentTixee'
          : 'rammInvestment',
      Cell: ({
        row: {
          original: { id, strategy },
        },
      }) => (
        <div className="InvestmentsTable__title">
          <span className="title">{strategy.title}</span>
          <span className="masterAccount">
            <FormattedMessage id={investmentIdSlug}>{txt => txt}</FormattedMessage>
            {` #${id}`}
          </span>
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'equity',
      Header:
        document.location.hostname === 'my.tixee.com' ||
        document.location.hostname === 'portal.disona.com' ||
        document.location.hostname === 'portal.rock-west.com'
          ? 'rammInvestmentEquityTixee'
          : 'rammInvestmentEquity',
      Cell: ({
        row: {
          original: { equity },
        },
      }) => <MoneyCell inTable value={equity} />,
    },
    {
      accessor: 'pnl',
      Header: 'rammCurrentIncome',
      Cell: ({
        row: {
          original: { pnl },
        },
      }) => <MoneyCell inTable value={pnl} />,
      isPreviewCell: true,
    },
    ...(!isNewInevest
      ? [
          {
            accessor: 'takeProfit',
            Header: 'justTakeProfit',
            Cell: ({
              row: {
                original: { takeProfit },
              },
            }) => (takeProfit ? <MoneyCell inTable value={takeProfit} /> : '一'),
          },
          {
            accessor: 'stopLoss',
            Header: 'justStopLoss',
            Cell: ({
              row: {
                original: { stopLoss },
              },
            }) => (stopLoss ? <MoneyCell inTable value={stopLoss} /> : '一'),
          },
        ]
      : []),
    ,
    {
      accessor: 'buttons',
      Header: () => {},
      Cell: ({
        row: {
          original: { id, strategy, status, enableOperations, currency },
        },
      }) => (
        <>
          <div className="InvestmentsTable__btns">
            {!isNewInevest && (!strategy.rollover || strategy.takeprofitStoplossInRollovers) && (
              <ActionsButton
                tooltipId={`justEditTpSl_${id}`}
                tooltipTextId="justEditTpSl"
                watch
                onClickFunc={e => onTableAction({ e, id, type: 'edit', strategyCurrency: strategy.currency })}>
                <EditIcon />
              </ActionsButton>
            )}
            {enableOperations && (
              <>
                <ActionsButton
                  tooltipId={`justDeposit_${id}`}
                  tooltipTextId={
                    document.location.hostname === 'portal.motforex.com'
                      ? 'justDepositIntoInvestmentMot'
                      : 'justDepositIntoInvestment'
                  }
                  watch
                  onClickFunc={e => onTableAction({ e, id, type: 'deposit', strategy })}>
                  <DepositIcon />
                </ActionsButton>
                <ActionsButton
                  tooltipId={`justWithdrawal_${id}`}
                  tooltipTextId="justWithdrawal"
                  watch
                  onClickFunc={e => onTableAction({ e, id, type: 'withdraw', status, strategy })}>
                  <WithdrawalIcon />
                </ActionsButton>
              </>
            )}
            <ActionsButton
              tooltipId={`rammActionDeactivate_${id}`}
              tooltipTextId="rammActionDeactivate"
              watch
              onClickFunc={e => onTableAction({ e, id, type: 'close', strategy })}>
              <CrossIcon />
            </ActionsButton>
          </div>
        </>
      ),
    },
  ];

  const closedColumns = [
    {
      accessor: 'strategy.id',
      Header: 'justStrategy',
      Cell: ({
        row: {
          original: { strategy },
        },
      }) => <span>{strategy.id}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'strategy.title',
      Header: 'justInvestment',
      Cell: ({
        row: {
          original: { strategy },
        },
      }) => <span className="title">{strategy.title}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'created',
      Header: 'justOpen',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    {
      accessor: 'closedAt',
      Header: 'justClosed',
      Cell: ({
        row: {
          original: { closedAt },
        },
      }) => <DateCell value={closedAt} />,
    },
    {
      accessor: 'age',
      Header: 'ageAndWeeks',
      Cell: ({
        row: {
          original: { age },
        },
      }) => <span>{age}</span>,
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount },
        },
      }) => <span>{amount ?? '一'}</span>,
    },
    {
      Header: () => null,
      id: 'rammShowStatement',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <DefaultButton
          textId="rammShowStatement"
          type="button"
          onClick={() => history.push(`/invest/investments/${id}`)}
          noBorder
        />
      ),
    },
  ];

  const requestsColumns = [
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'strategyName',
      Header: 'justStrategy',
      Cell: ({
        row: {
          original: { strategyName },
        },
      }) => <span className="title">{strategyName}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'operation',
      Header: 'justType',
      Cell: ({
        row: {
          original: { operation },
        },
      }) => <span>{operation}</span>,
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount },
        },
      }) => <span>{amount}</span>,
    },
    {
      accessor: 'currentAmount',
      Header: 'rammCurrentIncome',
      Cell: ({
        row: {
          original: { currentAmount },
        },
      }) => <span>{currentAmount}</span>,
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      Cell: ({
        row: {
          original: { status },
        },
      }) => <span>{status}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'created',
      Header: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    {
      Header: () => null,
      id: 'rammShowStatement',
      Cell: ({
        row: {
          original: { id, status },
        },
      }) =>
        status === 'Await' && !tempNewInvestDisableCanselRequestButton ? (
          <DefaultButton
            textId="justCancel"
            type="button"
            onClick={e => onTableAction({ e, id, type: 'cancel_request' })}
            noBorder
          />
        ) : null,
    },
  ];
  /* eslint-enable */

  const columns = activeTab => {
    switch (activeTab) {
      case 'closed':
        return closedColumns;
      case 'requests':
        return requestsColumns;
      default:
        return activeColumns;
    }
  };

  return (
    <div
      className={classNames('InvestmentsTable', {
        'InvestmentsTable-active': activeTab === 'active' && !isNewInevest,
        'InvestmentsTable-active--NEW': activeTab === 'active' && isNewInevest,
        'InvestmentsTable-closed': activeTab === 'closed',
        'InvestmentsTable-requests': activeTab === 'requests',
      })}>
      <PaginationTable
        {...props}
        data={data}
        columns={columns(activeTab)}
        onRowClick={(state, rowInfo) => onRowClick(rowInfo.original.id)}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      {activeTab !== prevTab && loading && <TableLoader />}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestmentsTable.propTypes = {
  data: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  prevTab: PropTypes.string.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  onTableAction: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestmentsTable.defaultProps = {
  theme: {},
};

export default InvestmentsTable;
