import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { withTheme } from 'common/styling/theme';
import { ModalHOC } from 'common';
import {
  PageContentPreloader,
  PageLayout,
  SearchPanel,
  PaginationTable,
  NoItemsPanel,
  NewDefaultButton,
  BasicModal,
} from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import classNames from 'classnames';
import EditInvestCRMStrategyForm from 'backoffice/investNew/_components/EditInvestCRMStrategyForm';
import { EditIcon } from 'common/icons';
import { Checkbox } from '../../_components';

import { boGetInvestCRMStrategies, boToggleInvestCRMStrategy } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const InvestStrategiesPageNew = ({
  showModal,
  getInvestStrategies,
  toggleStrategy,
  investFullAccessPerm,
  managerEditInvestStrategiesPerm,
  investCRMStrategies,
  investCRMStrategiesAreLoaded,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [modalType, setModalType] = useState('');
  const [formValue, setFormValue] = useState(null);

  useEffect(() => {
    getInvestStrategies({ search: query });
  }, [getInvestStrategies, query]);

  const openStrategyInvestors = strategyId => history.push(`/backoffice/invest_crm/${strategyId}`);

  const openInvestManagerAccount = accountId => history.push(`/backoffice/users/${accountId}`);

  const openEditStrategyModal = (type, initialValue, id) => {
    setIsOpen(true);
    setId(id);
    setModalType(type);
    setFormValue({ [type]: initialValue });
  };

  const onChangeStrategyAction = (e, strategyId) => {
    e.stopPropagation();

    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'rammConfirmStrategyActionClose',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => {
          toggleStrategy(strategyId, 'close', { search: query });
        },
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: 'boInvestTraderAccount',
        Cell: ({
          row: {
            original: { traderAccount },
          },
        }) => <span className="InvestStrategiesTable__cell">{traderAccount}</span>,
      },
      {
        Header: 'boInvestStrategy',
        Cell: ({
          row: {
            original: { title, id },
          },
        }) =>
          managerEditInvestStrategiesPerm ? (
            <button
              onClick={() => openEditStrategyModal('name', title, id)}
              className="InvestStrategiesTable__cell InvestStrategiesTable__cell--edit">
              <span className="InvestStrategiesTable__text">{title}</span> <EditIcon />
            </button>
          ) : (
            title
          ),
      },
      {
        accessor: 'equity',
        Header: 'boInvestEquity',
        Cell: ({
          row: {
            original: { equity },
          },
        }) => (
          <span className="InvestStrategiesTable__cell InvestStrategiesTable__cell--font-light">
            {equity.amount} {equity.currency ?? ''}
          </span>
        ),
      },
      {
        accessor: 'rewardPercentage',
        Header: 'justInvestRewardPercentage',
        Cell: ({
          row: {
            original: { rewardPercentage, id },
          },
        }) =>
          managerEditInvestStrategiesPerm ? (
            <button
              onClick={() => openEditStrategyModal('rewardPercentage', rewardPercentage, id)}
              className="InvestStrategiesTable__cell InvestStrategiesTable__cell--font-light InvestStrategiesTable__cell--edit">
              <span>{rewardPercentage}</span> <EditIcon />
            </button>
          ) : (
            rewardPercentage
          ),
      },
      {
        accessor: 'investors_number',
        Header: 'boInvestCurrentInvestor',
        Cell: ({
          row: {
            original: { id, investorsNumber },
          },
        }) => (
          <span
            onClick={() => openStrategyInvestors(id)}
            className="InvestStrategiesTable__cell InvestStrategiesTable__cell--investor-number InvestStrategiesTable__cell--link">
            {investorsNumber}
          </span>
        ),
      },
      {
        Header: 'boInvestRewardAccount',
        Cell: ({
          row: {
            original: { rewardAccount },
          },
        }) => (
          <span className="InvestStrategiesTable__cell InvestStrategiesTable__cell--font--medium">{rewardAccount}</span>
        ),
      },
      {
        Header: 'boInvestManager',
        Cell: ({
          row: {
            original: { trader },
          },
        }) => (
          <button
            onClick={() => openInvestManagerAccount(trader.id)}
            className="InvestStrategiesTable__cell InvestStrategiesTable__cell--blue">
            <span className="InvestStrategiesTable__text">{`${trader.firstName ?? ''} ${trader.lastName ?? ''}`}</span>
          </button>
        ),
      },
      {
        accessor: 'age',
        Header: 'boInvestAge',
        Cell: ({
          row: {
            original: { age },
          },
        }) => <span className="InvestStrategiesTable__cell">{age}</span>,
      },
      ...(investFullAccessPerm
        ? [
            {
              accessor: 'hidden',
              Header: 'boInvestShowInRating',
              Cell: ({
                row: {
                  original: { id, hidden },
                },
              }) => <Checkbox onClick={() => toggleStrategy(id, 'hide', { search: query })} checked={!hidden} />,
            },
          ]
        : []),
      {
        accessor: 'enabled',
        Header: 'boInvestStatus',
        Cell: ({
          row: {
            original: { id, status, investorsNumber },
          },
        }) => {
          const doesStrategyHaveInvestors = investorsNumber > 0;
          return (
            <div className="InvestStrategiesTable__cell InvestStrategiesTable__cell-font--medium  InvestStrategiesTable__cell--status">
              {!!status && (
                <NewDefaultButton
                  disabled={doesStrategyHaveInvestors}
                  tooltipTextId={doesStrategyHaveInvestors ? 'boInvestYouCantDisableStrategyWithInvestors' : ''}
                  textId="justDisable"
                  size="small"
                  variant="secondary"
                  onClick={e => {
                    onChangeStrategyAction(e, id);
                  }}
                />
              )}
              <div className={`status ${!!status ? 'active' : ''}`} />
            </div>
          );
        },
      },
    ],
    []
  );
  /* eslint-enable */

  return (
    <PageLayout captionSlug="menuInvestCRM">
      <PageContentPreloader ready={investCRMStrategiesAreLoaded} type="bigLine" firstLaunchOnly>
        <SearchPanel
          location={location}
          loading={!investCRMStrategiesAreLoaded}
          history={history}
          getList={getInvestStrategies}
          params={{ search: query }}
          searchTextId="boInvestSearchByAll"
        />
        {investCRMStrategies.count ? (
          <div className={classNames('InvestStrategiesTable', { full: investFullAccessPerm })}>
            <PaginationTable
              data={investCRMStrategies}
              columns={columns}
              loading={!investCRMStrategiesAreLoaded}
              getList={getInvestStrategies}
              location={location}
              history={history}
              params={{ search: query }}
              isNewTable
              noResponsive
            />
          </div>
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noStrategiesMatchingTheParameters" />
        )}
      </PageContentPreloader>
      <BasicModal
        captionId={modalType === 'name' ? 'justEditStrategyName' : 'justEditRewardPercentage'}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(!isOpen)}>
        <EditInvestCRMStrategyForm
          id={id}
          type={modalType}
          search={query}
          toggleModal={() => {
            setIsOpen(!isOpen);
          }}
          initialValues={formValue}
        />
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

InvestStrategiesPageNew.propTypes = {
  investCRMStrategies: PropTypes.object.isRequired,
  investCRMStrategiesAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategies: PropTypes.func.isRequired,
  toggleStrategy: PropTypes.func.isRequired,
  investFullAccessPerm: PropTypes.bool,
  managerEditInvestStrategiesPerm: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestStrategiesPageNew.defaultProps = {
  investFullAccessPerm: false,
  managerEditInvestStrategiesPerm: false,
  theme: {},
};

const ThemedInvestStrategiesPage = compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      investCRMStrategies: state.backoffice.investCRM.investCRMStrategies,
      investCRMStrategiesAreLoaded: state.backoffice.investCRM.investCRMStrategiesAreLoaded,
      investFullAccessPerm:
        state.user.profile.perms.investFullAccessPerm || state.user.profile.perms.managerFullAccessPerm,
      managerEditInvestStrategiesPerm: state.user.profile.perms.managerEditInvestStrategiesPerm,
    }),
    {
      getInvestStrategies: search => boGetInvestCRMStrategies.request(search),
      toggleStrategy: (strategyId, action, search) => boToggleInvestCRMStrategy.request(strategyId, action, search),
    }
  )
)(InvestStrategiesPageNew);

export default ThemedInvestStrategiesPage;
export { InvestStrategiesPageNew };
